<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner pt-6 md:pt-12">
      <h1 class="text-royalblue-900 mb-10">Get in touch</h1>
      <p class="h5 text-royalblue-400 mb-8 leading-relaxed">
        <span v-if="status === 'success'"
          >Thank you, we've received your message and will get back to you as
          soon as we can</span
        >
        <span v-if="status === 'error'"
          >Oops, something went wrong. Please try again.</span
        >
      </p>

      <div class="flex md:flex-row flex-col justify-between -mt-4 -mx-4">
        <div
          class="
            w-full
            md:w-1/2
            p-4
            pb-0
            flex flex-col
            md:items-start
            mb-8
            md:mb-0
          "
        >
          <form v-if="!status || status === 'error'" @submit="sendForm">
            <div
              class="mb-4 flex flex-col"
              :class="{ populated: form.name.populated }"
            >
              <input
                type="text"
                name="name"
                v-model="form.name.value"
                placeholder="Your full name..."
                class="order-2"
                required
                @change="validateFields(form.name)"
              />
              <label class="order-1" for="name">Name</label>
            </div>
            <div
              class="mb-4 flex flex-col"
              :class="{ populated: form.email.populated }"
            >
              <input
                type="email"
                name="email"
                v-model="form.email.value"
                placeholder="Enter your email address..."
                class="order-2"
                required
                @change="validateFields(form.email)"
              />
              <label class="order-1" for="email">Email Address</label>
            </div>
            <div
              class="mb-4 flex flex-col"
              :class="{ populated: form.message.populated }"
            >
              <textarea
                name="message"
                v-model="form.message.value"
                placeholder="Leave a message..."
                class="order-2"
                required
                @change="validateFields(form.message)"
              >
              </textarea>
              <label class="order-1" for="message">Message</label>
            </div>
            <input
              type="hidden"
              name="_gotcha"
            /><!-- use this to prevent spam -->
            <button type="submit" class="btn mb-8">
              Send
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </form>
          <p v-if="!status || status === 'error'">
            Or if you prefer please call the
            <router-link :to="{ name: 'Meet The Team' }">physio</router-link>
            who covers your geographical area.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
form {
  @apply w-full;

  label {
    @apply font-medium tracking-tightest transition duration-300 ease-out transform translate-y-9 translate-x-3 pointer-events-none;
  }

  input,
  textarea {
    @apply p-2 border-2 bg-white rounded-sm border-black text-base text-royalblue-900 leading-base cursor-pointer transition-colors duration-300 w-full appearance-none;

    &::placeholder {
      @apply transition duration-500 ease-out opacity-0;
    }

    &:focus {
      @apply rounded-sm border-royalblue-300 appearance-none outline-none;

      &::placeholder {
        @apply text-base opacity-100;
      }

      & + label {
        @apply text-royalblue-400 translate-y-0 translate-x-0;
      }
    }
  }

  .populated label {
    @apply text-royalblue-400 translate-y-0 translate-x-0;
  }

  .btn {
    @apply inline-flex items-center py-2 px-4 border-2 bg-white rounded-sm border-royalblue-400 text-base text-royalblue-900 font-medium leading-base cursor-pointer transition duration-300 appearance-none;

    &:hover,
    &:focus {
      @apply bg-royalblue-400 text-white;

      svg {
        @apply translate-x-1.5;
      }
    }

    &:focus {
      @apply focus:outline-none;
    }

    svg {
      @apply transform-gpu transition-transform duration-300;
    }
  }
}
</style>

<script>
export default {
  name: 'Contact',
  data: function () {
    return {
      status: null,
      form: {
        name: {
          value: null,
          error: null,
          populated: null
        },
        email: {
          value: null,
          error: null,
          populated: null
        },
        message: {
          value: null,
          error: null,
          populated: null
        }
      }
    }
  },
  methods: {
    sendForm: function (event) {
      event.preventDefault()

      fetch('https://formcarry.com/s/aYv9IuamNdw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          name: this.form.name.value,
          email: this.form.email.value,
          message: this.form.message.value
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.code === 200) {
            this.status = 'success'
          } else {
            // Formcarry error
            this.status = 'error'
          }
        })
        // network error
        .catch(() => (this.status = 'error'))
    },
    validateFields: function (input) {
      let isValid = true

      if (input.value == null || input.value == '') {
        input.error = 'This field is required'
        input.populated = false
        isValid = false
      } else {
        input.error = null
        input.populated = true
      }

      return isValid
    }
  }
}
</script>
